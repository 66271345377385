import request from '@/api/request.js'

// 查询工单列表
export function listOrder(query) {
  return request({
    url: '/system/order/list',
    method: 'get',
    params: query
  })
}

// 查询工单详细
export function getOrder(id) {
  return request({
    url: '/system/order/' + id,
    method: 'get'
  })
}

// 新增工单
export function addOrder(data) {
  return request({
    url: '/system/order',
    method: 'post',
    data: data
  })
}

// 修改工单
export function updateOrder(data) {
  return request({
    url: '/system/order',
    method: 'put',
    data: data
  })
}

// 删除工单
export function delOrder(id) {
  return request({
    url: '/system/order/' + id,
    method: 'delete'
  })
}

// 查询派单模式
export function listDispatch() {
  return request({
    url: '/system/order/dispatch',
    method: 'get'
  })
}

// 修改派单模式
export function updateDispatch(data) {
  return request({
    url: '/system/order/dispatch',
    method: 'put',
    data: data
  })
}

// 获取表单信息
export function getCode() {
  return request({
    url: '/system/order/code',
    method: 'get'
  })
}

// 获取运维人员在线信息
export function getOpsuser(areaName, faultName) {
  return request({
    url: '/system/order/user',
    method: 'get',
    params: {
      areaName: areaName,
      faultName: faultName
    }
  })
}
